<template>
  <div>
    <title-bar :title="$t('navigations.orders')"/>
    <div v-if="this.rows" class="custom-table">
      <table-actions
        :actions="['search', 'addBtn', 'perPage']"
        :addBtnText="$t('messages.orderCreate')"
        :addBtnLink="$helper.getAddUrl('order')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:id="data">
          <router-link :to="$helper.getEditUrl('order', data.value.id)">{{ data.value.id }}</router-link>
        </template>
        <template v-slot:createdAt="data">
          {{ $helper.formatDate(data.value.createdAt) }} {{ $helper.formatTime(data.value.createdAt) }}
        </template>
        <template v-slot:product="data">
          <strong>{{
              data.value.resort.name
            }}</strong><br>
          {{ data.value.room.name }}
        </template>
        <template v-slot:user="data">
          <span v-if="data.value.client">
            {{ data.value.client.givenName + ' ' + data.value.client.familyName }}
          </span>
        </template>
        <template v-slot:totalPrice="data">
          {{ data.value.totalPrice }} {{ data.value.currency }}
        </template>
        <template v-slot:fromDate="data">
          {{ $helper.formatDate(data.value.fromDate) }}
        </template>
        <template v-slot:toDate="data">
          {{ $helper.formatDate(data.value.toDate) }}
        </template>
        <template v-slot:status="data">
          <b-badge :variant="orderStatusClass(data.value.status)">{{ data.value.status }}</b-badge>
        </template>
        <template v-slot:financialStatus="data">
          <b-badge :variant="orderStatusClass(data.value.financialStatus)">{{ data.value.financialStatus }}</b-badge>
        </template>
        <template v-slot:paymentMethod="data">
          <b-badge variant="primary">{{ data.value.paymentMethod.replace('_', " ") }}</b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('order', data.value.id)"/>
            <delete-btn @pressDelete="deleteOrder(data.value['@id'])"/>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import {BBadge} from 'bootstrap-vue-3'
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";

import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";

export default {
  name: "AdminCustomersListing",
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar,
    BBadge
  },
  data() {
    return {
      search: "",
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      sortable: {
        order: "createdAt",
        sort: "DESC",
      },
      columns: [
        {
          label: '#',
          field: "id",
          sortable: false,
        },
        {
          label: this.$t('forms.createdAt'),
          field: "createdAt",
          sortable: true,
        },
        {
          label: this.$t('forms.product'),
          field: "product",
          sortable: false,
        },
        {
          label: this.$t('forms.client'),
          field: "user",
          sortable: false,
        },
        {
          label: this.$t('forms.totalPrice'),
          field: "totalPrice",
          sortable: false,
        },
        {
          label: this.$t('forms.fromDate'),
          field: "fromDate",
          sortable: false,
        },
        {
          label: this.$t('forms.toDate'),
          field: "toDate",
          sortable: false,
        },
        {
          label: this.$t('forms.status'),
          field: "status",
          sortable: true,
        },
        {
          label: this.$t('forms.financialStatus'),
          field: "financialStatus",
          sortable: true,
        },
        {
          label: this.$t('forms.paymentMethod'),
          field: "paymentMethod",
          sortable: false,
        },
        {
          label: this.$t('forms.actions'),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadOrders();
  },
  methods: {
    loadOrders() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      params["order[" + this.sortable.order + "]"] = this.sortable.sort;
      params['status'] = ['PROCESSING', 'AVAILABLE', 'CONFIRMED', 'MODIFIED', 'UNAVAILABLE', 'PENDING', 'REFUNDED', 'CANCELLED', 'COMPLETED']
      this.$Orders.getCollection({params}, 'order_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadOrders();
    },
    orderStatusClass(status) {
      if (['PENDING','CONFIRMED','COMPLETED','PAID','AUTHORIZED','MODIFIED','REFUNDED','UNAVAILABLE','AVAILABLE','CANCELLED',].includes(status)) {
        return status.toLowerCase()
      }

      return 'primary'
    },
    deleteOrder(url) {
      this.$Orders
        .deleteResourceByUrl({url})
        .then((response) => {
          if (response.status === 204) {
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              this.$t('messages.orderDeleted'),
              "",
              "success"
            );
            this.loadOrders();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$emit("clearAction");
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    },
    setSort(offset, limit, order, sort) {
      this.sortable.sort = sort;
      this.sortable.order = order;
      this.loadOrders();
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadOrders();
    },
    onPageChange() {
      this.loadOrders();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadOrders();
    },
  },
};
</script>

<style lang="scss">
</style>
